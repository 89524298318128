import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default () => {
  return (
    <>
      <article id="feature" className="bg-blueishgrey py-16 md:py-20">
        <h2 className="text-xl md:text-2xl text-darkappgreen font-bold text-center">
          FEATURE
        </h2>

        <div className="text-textnearblack text-center">
          <h2 className="text-2xl md:text-4xl font-bold py-4 tracking-wide mb-6 md:mb-10">
            <span className="px-2">主要機能</span>
          </h2>
        </div>


        <div className="text-sm md:text-xl font-medium text-textnearblack w-4/5 md:w-1/2 mx-auto text-center">
          <p>
            タブレットアプリと専用管理画面でサポート！
            <br />
            詳細はお問い合わせくださいませ。
          </p>
        </div>

        <div className="mt-6 md:mt-20 flex flex-row w-4/5 mx-auto justify-center items-center">
          <div className="hidden md:block md:basis-1/6">
            <StaticImage
              src="../images/for_customer.png"
              alt="お客様向け"
              objectFit="contain"
              className="w-2/3 mx-auto"
            />
          </div>
          <div className="md:basis-3/6">
            <StaticImage
              src="../images/features.png"
              alt="主要機能"
              objectFit="contain"
              className=""
            />
          </div>
          <div className="hidden md:block md:basis-1/6">
            <StaticImage
              src="../images/for_stuff.png"
              alt="スタッフ向け"
              objectFit="contain"
              className="w-4/5 ml-16"
            />
          </div>
        </div>
      </article>
    </>
  );
};
