import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";

export default () => (
  <footer className="bg-bluedark">
    <div className="w-4/5 md:w-2/3 flex justify-between mx-auto pt-10 pb-10 md:pb-20">
      <div className="w-1/3 md:w-1/6">
        <AnchorLink
          to="/"
          title="トップページ"
          className="sm:px-3 px-1 sm:mx-3 mx-1 hover:opacity-75"
        >
          <StaticImage
            src="../images/icons/roomport-icon-green-white.png"
            alt="roomport アイコン"
            loading="eager"
          />
        </AnchorLink>
      </div>
      <div className="text-blueishgrey w-2/5 text-xs md:text-base">
        <p>
          <a href="https://www.hosty.jp/" target="_blank" className="mr-4 md:mr-6">
            運営会社
          </a>
          <br className="block md:hidden pb-2" />
          <a href="https://www.hosty.jp/privacy-policy" target="_blank">
            プライバシーポリシー
          </a>
        </p>
      </div>
    </div>
    <p className="text-center text-darkgrey pb-2 text-xs md:text-base">
      &copy;{new Date().getFullYear()} Hosty inc.
    </p>
  </footer>
);
