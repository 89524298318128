import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default () => {
  return (
    <>
      <article id="about" className="text-center my-16 md:mt-20 md:mb-32">
        <h2 className="text-xl md:text-2xl text-darkappgreen font-bold text-center">
          ABOUT
        </h2>

        <div className="text-textnearblack text-center">
          <h2 className="text-2xl md:text-4xl font-bold py-4 tracking-wide mb-6 md:mb-10">
            <span className="px-2">roomportとは？</span>
          </h2>
        </div>
        <div className="w-4/5 md:w-1/2 mx-auto">
          <StaticImage
            src="../images/What_is_roomport.png"
            alt="roomport概念図"
          />
        </div>
        <div className="text-sm md:text-xl font-medium text-textnearblack w-4/5 md:w-1/2 mx-auto">
          <p>
            宿泊施設に滞在中のお客様とスタッフとを繋ぐ客室タブレットアプリです。
            <br className="hidden md:block" />
            DXを駆使し、
            <span className="text-darkappgreen">
              業務効率化・コスト削減・顧客満足度UP
            </span>
            に貢献します。
          </p>
        </div>
      </article>
    </>
  );
};
