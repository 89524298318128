import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as Sentry from "@sentry/gatsby";

const isBrowser = typeof window !== "undefined";

export default () => {
  const [value, setValue] = React.useState({
    contactCategory: "お見積りの依頼",
  });

  const [submitStatus, setSubmitStatus] = React.useState(`送信`);


    function handleChange(e: any) {
      value[e.target.id] = e.target.value;
      setValue({ ...value });
    }

    async function onSubmit(e: any) {
      e.preventDefault();
      // 送信中ステータスへの変換処理まわり
      setSubmitStatus(`送信中`);
        if (isBrowser) {
          const submitButton = document.getElementById("submitButton");

          submitButton.disabled = true;
          submitButton.classList.remove("bg-appgreen", "hover:opacity-80");
          submitButton.classList.add("bg-darkgrey");

          await window
            .fetch(`/api/form`, {
              method: `POST`,
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(value),
            })
            .then((response) => {
              if (response.ok) {
                // 送信完了ステータスへの変換処理まわり
                setSubmitStatus(`送信完了！`);
                submitButton.remove();
              } else {
                console.error(response.status);
                console.error(JSON.stringify(response));
                // 送信失敗ステータスへの変換処理まわり
                setSubmitStatus(`送信失敗`);
                submitButton.remove();
                Sentry.captureException(error);
              }
            })
            .catch((error) => {
              // 送信失敗ステータスへの変換処理まわり
              console.error(JSON.stringify(error));
              setSubmitStatus(`送信失敗`);
              submitButton.remove();
              Sentry.captureException(error);
            });
        }
    }


  return (
    <>
      <article id="contact" className="bg-blueishgrey py-16 md:py-20">
        <h2 className="text-xl md:text-2xl text-darkappgreen font-bold text-center">
          CONTACT
        </h2>

        <div className="text-textnearblack text-center">
          <h2 className="text-2xl md:text-4xl font-bold py-4 tracking-wide mb-6 md:mb-10">
            <span className="px-2">お問い合わせ</span>
          </h2>
        </div>
        <div className="w-3/4 md:w-2/3 mx-auto bg-white rounded-2xl py-10 shadow-sm">
          <form
            onSubmit={onSubmit}
            method="POST"
            action="/api/form"
            className="w-3/4 md:w-2/3 mx-auto"
          >
            <div className="mb-8">
              <label
                className="block text-textnearblack font-bold mb-3"
                htmlFor="contactCategory"
              >
                お問い合わせ種別 *
              </label>
              <div className="relative">
                <select
                  id="contactCategory"
                  value={value[`contactCategory`] || ``}
                  onChange={handleChange}
                  className="shadow appearance-none border border-blueishgrey rounded-3xl w-full py-3 px-4 text-greendark"
                  required
                >
                  <option value="" disabled>
                    --選択してください--
                  </option>
                  <option value="お見積りの依頼">お見積りの依頼</option>
                  <option value="製品に関するお問い合わせ">
                    製品に関するお問い合わせ
                  </option>
                  <option value="その他">その他</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2 text-greendark">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <label
                className="block text-textnearblack font-bold mb-3"
                htmlFor="companyOrPropertyName"
              >
                会社名または施設名
              </label>
              <input
                id="companyOrPropertyName"
                value={value[`companyOrPropertyName`] || ``}
                onChange={handleChange}
                className="form-element shadow appearance-none border border-blueishgrey rounded-3xl w-full py-3 px-4 text-greendark"
                type="text"
                autoComplete="organization"
              />
            </div>
            <div className="mb-8">
              <label
                className="block text-textnearblack font-bold mb-3"
                htmlFor="estimatedNum"
              >
                導入検討台数
              </label>
              <input
                id="estimatedNum"
                value={value[`estimatedNum`] || ``}
                onChange={handleChange}
                className="form-element shadow appearance-none border border-blueishgrey rounded-3xl w-full py-3 px-4 text-greendark"
                type="text"
                inputMode="numeric"
              />
            </div>
            <div className="mb-8">
              <label
                className="block text-textnearblack font-bold mb-3"
                htmlFor="name"
              >
                氏名 *
              </label>
              <input
                id="name"
                value={value[`name`] || ``}
                onChange={handleChange}
                className="form-element shadow appearance-none border border-blueishgrey rounded-3xl w-full py-3 px-4 text-greendark"
                type="text"
                autoComplete="name"
                required
              />
            </div>
            <div className="mb-8">
              <label
                className="block text-textnearblack font-bold mb-3"
                htmlFor="tel"
              >
                電話番号 *
              </label>
              <input
                id="tel"
                value={value[`tel`] || ``}
                onChange={handleChange}
                className="form-element shadow appearance-none border border-blueishgrey rounded-3xl w-full py-3 px-4 text-greendark"
                type="tel"
                autoComplete="tel"
                required
              />
            </div>
            <div className="mb-8">
              <label
                className="block text-textnearblack font-bold mb-3"
                htmlFor="email"
              >
                メールアドレス *
              </label>
              <input
                id="email"
                value={value[`email`] || ``}
                onChange={handleChange}
                className="form-element shadow appearance-none border border-blueishgrey rounded-3xl w-full py-3 px-4 text-greendark"
                type="email"
                autoComplete="email"
                required
              />
            </div>
            <div className="mb-8">
              <label
                className="block text-textnearblack font-bold mb-3"
                htmlFor="inquiryContent"
              >
                お問い合わせ内容
              </label>
              <textarea
                id="inquiryContent"
                value={value[`inquiryContent`] || ``}
                onChange={handleChange}
                className="form-element shadow appearance-none border border-blueishgrey rounded-3xl w-full h-48 py-3 px-4 text-greendark"
              />
            </div>


            <div className="text-center">
              <button
                id="submitButton"
                type="submit"
                className="rounded-2xl px-20 py-3 text-xl font-semibold hover:opacity-80 bg-appgreen text-white"
              >
                {submitStatus}
                {submitStatus == "送信中" ? (
                  <div className="inline-block animate-spin h-5 w-5 border-4 ml-3 border-t-darkgrey border-white rounded-full"></div>
                ) : (
                  ``
                )}
              </button>
            </div>
            {/* 送信中メッセージ  */}
            {submitStatus == "送信中" ? (
              <div className="text-center font-base text-greendark text-base mt-5">
                少々お待ちください...
              </div>
            ) : (
              ``
            )}

            {/* 送信完了メッセージ  */}
            {submitStatus == "送信完了！" ? (
              <div className="text-center font-base text-darkappgreen text-lg mt-5">
                <p className="text-2xl font-bold mb-3">{submitStatus}</p>
                お問い合わせが完了しました。
                <br />
                担当者よりメールにてご返信致しますので、お待ちくださいませ。
              </div>
            ) : (
              ``
            )}
            {/* 送信失敗メッセージ  */}
            {submitStatus == "送信失敗" ? (
              <div className="text-center font-base text-darkgrey text-lg mt-5">
                <p className="text-2xl font-bold mb-3">{submitStatus}</p>
                申し訳ありません。
                <br />
                しばらく時間を置いてから、再度お試しくださいませ。
              </div>
            ) : (
              ``
            )}
          </form>
        </div>
      </article>
    </>
  );
};
