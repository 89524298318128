import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default () => {
  return (
    <>
      <article
        id="hero"
        className="flex lg:flex-row flex-col w-full bg-whitishgrey lg:px-32 md:px-16 px-10"
      >
        <div className="lg:basis-[46%] lg:text-left text-center">
          <div className="lg:w-3/4 w-full mx-auto">
            <div className="block lg:hidden text-textnearblack">
              <h2 className="md:text-4xl text-3xl font-bold pb-5 md:pt-8 pt-6 tracking-wide">
                <span className="md:pl-2 md:pr-0 px-2">宿泊体験と</span>
                <br className="md:hidden" />
                <div className="md:hidden h-2"></div>
                <span className="md:pr-2 md:pl-0 px-2">運営業務をDX</span>
              </h2>
            </div>

            <h3 className="lg:pt-16 md:pt-5 pt-3 lg:text-2xl md:text-xl text-lg font-bold lg:text-left text-center text-textnearblack">
              宿泊施設向けタブレットアプリ
            </h3>
            <div className="md:w-60 w-48 pt-2 lg:mx-0 mx-auto">
              <StaticImage
                src="../images/icons/roomport-green-black-icon-bg_transparent-without-padding-x.png"
                alt="roomport アイコン"
                loading="eager"
              />
            </div>

            <div className="hidden lg:block text-textnearblack">
              <h2 className="text-5xl font-bold pb-5 pt-8 tracking-wide">
                <span className="pr-4">宿泊体験と</span>
              </h2>
              <h2 className="text-5xl font-bold tracking-wide">
                <span className="pr-4">運営業務をDX</span>
              </h2>
            </div>

            <div className="lg:hidden">
              <div className="w-full pt-6 md:pt-8 max-w-xl mx-auto fadeIn">
                <StaticImage
                  src="../images/hero-tablet-image.png"
                  alt="roomport アイコン"
                  loading="eager"
                />
              </div>
            </div>

            <div className="md:my-11 my-9 text-sm md:text-base text-textnearblack">
              <p className="mb-3">
                客室滞在中のアクションをタブレット１台で完結！
              </p>
              <p>
                roomportで宿泊施設のオペレーションを効率化させ、
                お客様とのコミュニケーションをスムーズに
              </p>
            </div>
            <AnchorLink to="/#contact" title="お問い合わせ">
              <button className="bg-bluedark hover:opacity-75 text-white rounded-lg px-6 py-3 font-medium">
                お問い合わせ
              </button>
            </AnchorLink>
            <div className="md:h-20 h-10"></div>
          </div>
        </div>
        <div className="lg:basis-[54%] hidden lg:block">
          <div className="w-full pt-6 max-w-xl fadeIn">
            <StaticImage
              src="../images/hero-tablet-image.png"
              alt="roomport アイコン"
              loading="eager"
            />
          </div>
        </div>
      </article>
    </>
  );
};
