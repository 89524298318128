import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export const SEO = ({ title, description, pathname, children }:any) => {
    const {
        title: defaultTitle,
        description: defaultDescription,
        siteUrl,
        ogpImage
    } = useSiteMetadata();

    const seo = {
      title: title || defaultTitle,
      description: description || defaultDescription,
      url: `${siteUrl}${pathname || ``}`,
      ogpImage: `${siteUrl}${ogpImage}`
    };

    return (
      <>
        <title>{seo.title}</title>
        <meta name="theme-color" content="#F2F2F2"></meta>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.ogpImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:url" content={seo.url} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.ogpImage} />
        <link
          rel="icon"
          type="image/png"
          href={"/images/favicon.png"}
          sizes="16x16 24x24 32x32 48x48 64x64"
        />
        {children}
      </>
    );
};
