import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";


const isBrowser = typeof window !== "undefined"

export default () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuTap = () => {
    setOpenMenu(!openMenu);
  };

   if (isBrowser) {
     window.addEventListener("scroll", function () {
       const headerElement = document.getElementById("header");
       const headerHeight = headerElement.clientHeight;
       const aboutElement = document.getElementById("about");
       const aboutRect = aboutElement.getBoundingClientRect();
       const aboutRectTop =
         aboutRect.top + window.pageYOffset - headerHeight * 2;
       const featureElement = document.getElementById("feature");
       const featureRect = featureElement.getBoundingClientRect();
       const featureRectTop =
         featureRect.top + window.pageYOffset - headerHeight;
       const pricingElement = document.getElementById("pricing");
       const pricingRect = pricingElement.getBoundingClientRect();
       const pricingRectTop =
         pricingRect.top + window.pageYOffset - headerHeight;
       const contactElement = document.getElementById("contact");
       const contactRect = contactElement.getBoundingClientRect();
       const contactRectTop =
         contactRect.top + window.pageYOffset - headerHeight;
       const otherProductElement = document.getElementById("other-products");
       const otherProductRect = otherProductElement.getBoundingClientRect();
       const otherProductRectTop =
         otherProductRect.top + window.pageYOffset - headerHeight;

       /* 背景白 */
       if (
         (window.pageYOffset > aboutRectTop &&
           window.pageYOffset < featureRectTop) ||
         (window.pageYOffset > pricingRectTop &&
           window.pageYOffset < contactRectTop) ||
         window.pageYOffset > otherProductRectTop
       ) {
         headerElement.classList.add("white-overed");
       } else {
         headerElement.classList.remove("white-overed");
       }

       /* 背景 blueish grey */
       if (
         (window.pageYOffset > featureRectTop &&
           window.pageYOffset < pricingRectTop) ||
         (window.pageYOffset > contactRectTop &&
           window.pageYOffset < otherProductRectTop)
       ) {
         headerElement.classList.add("blueish-overed");
       } else {
         headerElement.classList.remove("blueish-overed");
       }
     });
   };

  return (
    <>
      <header id="header" className="fixed w-full z-50 backdrop-blur-sm">
        <nav className="md:max-w-6xl flex justify-between mx-auto h-14 md:h-16">
          <div className="w-44 h-max self-center">
            <AnchorLink
              to="/"
              title="トップページ"
              className="sm:px-3 px-1 sm:mx-3 mx-1 hover:opacity-75"
            >
              <StaticImage
                src="../images/icons/roomport-green-black-icon-bg_transparent.png"
                alt="roomport アイコン"
                loading="eager"
              />
            </AnchorLink>
          </div>

          {/* PCレイアウトナビゲーション */}
          <div className="hidden sm:flex navigation-menu font-medium justify-around">
            <AnchorLink
              to="/#point"
              title="POINT"
              className="self-center px-3 mx-3"
            >
              <span className="hover:opacity-75">POINT</span>
            </AnchorLink>
            <AnchorLink
              to="/#feature"
              title="機能"
              className="self-center px-3 mx-3"
            >
              <span className="hover:opacity-75">機能</span>
            </AnchorLink>
            <AnchorLink
              to="/#pricing"
              title="料金"
              className="self-center px-3 mx-3"
            >
              <span className="hover:opacity-75">料金</span>
            </AnchorLink>
            <AnchorLink
              to="/#contact"
              title="お問い合わせ"
              className="self-center px-3 mx-3"
            >
              <button className="bg-bluedark hover:opacity-75 text-white rounded-lg px-4 py-2 font-medium">
                お問い合わせ
              </button>
            </AnchorLink>
          </div>

          {/* SPレイアウトナビゲーション */}
          <div className="sm:hidden flex items-center mr-3">
            <button
              onClick={menuTap}
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {/* ハンバーガーメニュー開閉 */}
              {!openMenu ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              ) : (
                <div>
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              )}
            </button>
          </div>
        </nav>

        {openMenu ? (
          <div className="flex flex-col navigation-menu mobile-menu-content font-medium">
            <div className="text-right mr-12 p-1 m-1">
              <AnchorLink
                to="/#point"
                title="POINT"
                className="self-center"
                onAnchorLinkClick={menuTap}
              >
                <span className="hover:opacity-75">POINT</span>
              </AnchorLink>
            </div>
            <div className="text-right mr-12 p-1 m-1">
              <AnchorLink
                to="/#feature"
                title="機能"
                className="self-center"
                onAnchorLinkClick={menuTap}
              >
                <span className="hover:opacity-75">機能</span>
              </AnchorLink>
            </div>
            <div className="text-right mr-12 p-1 m-1">
              <AnchorLink
                to="/#pricing"
                title="料金"
                className="self-center"
                onAnchorLinkClick={menuTap}
              >
                <span className="hover:opacity-75">料金</span>
              </AnchorLink>
            </div>
            <div className="text-right mr-12 p-1 mt-1 mb-3">
              <AnchorLink
                to="/#contact"
                title="お問い合わせ"
                className="self-center"
                onAnchorLinkClick={menuTap}
              >
                <button className="bg-bluedark hover:opacity-75 text-white rounded-lg px-4 py-2 font-medium">
                  お問い合わせ
                </button>
              </AnchorLink>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </header>
      {/*　ヘッダーによって占領してしまう領域の確保 */}
      <div className="h-14 md:h-16"></div>
    </>
  );
};
