import React from "react"
import type { PageProps } from "gatsby";
import Header from "./header"
import Footer from "./footer"

export default ({ children }: any) => (
  <div className="relative">
    <Header />
    {children}
    <Footer />
  </div>
);
