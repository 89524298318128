import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import appImageGif from "../images/roomport_app_transition.gif";

export default () => {
  return (
    <>
      <article id="point" className="my-20">
        <h3 className="text-xl md:text-2xl text-darkappgreen font-bold text-center">
          POINT
        </h3>

        <div className="text-textnearblack text-center">
          <h2 className="text-2xl md:text-4xl font-bold py-4 tracking-wide mb-6 md:mb-16">
            <span className="px-2">PBXよりも多機能で安価</span>
          </h2>
        </div>

        <div className="w-full lg:px-36 md:px-16 px-10">
          <section className="flex lg:flex-row flex-col">
            <div className="basis-1/2 relative">
              <div className="absolute top-6 md:top-7 left-0 bg-whitishgrey w-full h-full rounded-2xl z-0"></div>
              <div className="w-5/6 mx-auto relative z-10">
                <span className="block text-4xl md:text-5xl font-semibold text-darkappgreen">
                  01
                </span>
                <div className="text-xl md:text-3xl font-semibold py-5 leading-snug">
                  <p>
                    客室コミュニケーションをDXし、
                    <br className="hidden md:block" />
                    業務効率化・工数削減
                  </p>
                </div>
                <div className="hidden md:block text-base">
                  <p>
                    お客様からのお問い合わせに対し、音声通話だけでなく、
                    <br className="hidden md:block" />
                    専用管理画面からチャットやビデオ通話が可能。
                  </p>
                  <p>
                    また、チャットボットも搭載されており、スタッフの工数削減にも貢献。
                  </p>
                </div>
                <div className="mt-3 md:mt-10 font-semibold text-sm md:text-base">
                  <p className="my-3 md:my-0 md:leading-7">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="check-mark text-base md:text-xl"
                    />
                    <span className="pl-2">
                      <span className="text-darkappgreen">
                        チャット・音声通話・ビデオ通話
                      </span>
                      など多彩な手段を用意
                    </span>
                  </p>
                  <p className="my-3 md:my-0 md:leading-7">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="check-mark text-base md:text-xl"
                    />
                    <span className="pl-2">
                      よくあるご質問は
                      <span className="text-darkappgreen">チャットボット</span>
                      が自動回答
                    </span>
                  </p>
                  <p className="md:leading-7">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="check-mark text-base md:text-xl"
                    />
                    <span className="pl-2">
                      LINEのように不在着信や未読メッセージバッジ表示も
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="basis-1/2">
              <StaticImage
                src="../images/room_communication_conceptual_diagram.png"
                alt="客室コミュニケーションDX概念図"
                objectFit="contain"
                className="max-w-md mx-3 md:mx-16 mt-16 md:mt-8"
              />
            </div>
          </section>

          <section className="mt-10 md:mt-28 flex lg:flex-row flex-col">
            <div className="basis-1/2 hidden md:block">
              <div className="relative max-w-lg mt-10 md:mt-16 mx-auto">
                <img
                  src={appImageGif}
                  alt="アプリイメージGIF"
                  className="w-4/5 absolute top-10 left-7 z-10"
                />
                <StaticImage
                  src="../images/tablet_vessel.png"
                  alt="タブレット枠画像"
                  objectFit="contain"
                  className="w-11/12 absolute top-0 left-0 z-0"
                />
              </div>
            </div>
            <div className="basis-1/2 relative">
              <div className="absolute top-6 md:top-7 left-0 bg-whitishgrey w-full h-full rounded-2xl z-0"></div>
              <div className="w-5/6 mx-auto relative z-10">
                <span className="block text-4xl md:text-5xl font-semibold text-darkappgreen">
                  02
                </span>
                <div className="text-xl md:text-3xl font-semibold py-5 leading-snug">
                  <p>
                    滞在中のアクションを完結でき、
                    <br className="hidden md:block" /> 顧客満足度UP
                  </p>
                </div>
                <div className="hidden md:block text-base">
                  <p>
                    WiFi情報確認・チェックアウト・客室マニュアルや宿泊約款の閲覧など、
                    滞在中のお客様が知りたいこと・やりたいことはタブレットから簡単に実行可能！
                  </p>
                </div>
                <div className="mt-3 md:mt-10 font-semibold text-sm md:text-base md:pb-10">
                  <p className="my-3 md:my-0 md:leading-7">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="check-mark text-base md:text-xl"
                    />
                    <span className="pl-2">
                      <span className="text-darkappgreen">チェックアウト</span>
                      もタブレットから可能にし、清掃の効率化へ
                    </span>
                  </p>
                  <p className="my-3 md:my-0 md:leading-7">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="check-mark text-base md:text-xl"
                    />
                    <span className="pl-2">
                      表示コンテンツはオンラインで管理し、
                      <span className="text-darkappgreen">ペーパーレス化</span>
                      を実現
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="basis-1/2 block md:hidden">
              <div className="relative max-w-lg mt-10 ml-3 mx-auto">
                <img
                  src={appImageGif}
                  alt="アプリイメージGIF"
                  className="w-3/4 absolute top-10 left-6 z-10"
                />
                <StaticImage
                  src="../images/tablet_vessel.png"
                  alt="タブレット枠画像"
                  objectFit="contain"
                  className="w-11/12 absolute top-3 left-0 z-0"
                />
              </div>
            </div>
          </section>

          <section className="mt-56 md:mt-28 my-20 md:my-40 flex lg:flex-row flex-col">
            <div className="basis-1/2 relative">
              <div className="absolute top-6 md:top-7 left-0 bg-whitishgrey w-full h-full rounded-2xl z-0"></div>
              <div className="w-5/6 mx-auto relative z-10 md:pb-8">
                <span className="block text-4xl md:text-5xl font-semibold text-darkappgreen">
                  03
                </span>
                <div className="text-xl md:text-3xl font-semibold py-5 leading-snug">
                  <p>貴社施設に最適化</p>
                </div>
                <div className="hidden md:block text-base">
                  <p>
                    専用管理画面から貴社施設に合うようアプリをカスタマイズ可能。
                    <br className="hidden md:block" />
                    roomportで顧客体験を更に充実させましょう。
                  </p>
                </div>
                <div className="mt-3 md:mt-10 font-semibold text-sm md:text-base">
                  <p className="my-3 md:my-0 md:leading-7">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="check-mark text-base md:text-xl"
                    />
                    <span className="pl-2">
                      施設ロゴ表示・テーマカラーの設定で
                      <span className="text-darkappgreen">ブランディング</span>
                    </span>
                  </p>
                  <p className="my-3 md:my-0 md:leading-7">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="check-mark text-base md:text-xl"
                    />
                    <span className="pl-2">
                      表示メニューやコンテンツを施設ごとに
                      <span className="text-darkappgreen">カスタマイズ</span>
                    </span>
                  </p>
                  <p className="md:leading-7">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="check-mark text-base md:text-xl"
                    />
                    <span className="pl-2">
                      <span className="text-darkappgreen">どんな施設でも</span>
                      、WiFiさえあればOK
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="basis-1/2">
              <StaticImage
                src="../images/point_3.png"
                alt="optimize_image"
                objectFit="contain"
                className="max-w-2xl ml-4 md:ml-20 mt-16"
              />
            </div>
          </section>
        </div>
      </article>
    </>
  );
};
