import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default () => {
  return (
    <>
      <article id="pricing" className="py-16 md:py-20 w-4/5 mx-auto">
        <h2 className="text-xl md:text-2xl text-darkappgreen font-bold text-center">
          PRICING
        </h2>

        <div className="text-textnearblack text-center">
          <h2 className="text-2xl md:text-4xl font-bold py-4 tracking-wide mb-8 md:mb-12">
            <span className="px-2">料金</span>
          </h2>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center md:mb-10">
          <div className="w-full md:basis-5/12 bg-whitishgrey text-center px-4 py-6 rounded-2xl shadow-md">
            <h4 className="text-2xl md:text-3xl text-appgreen font-semibold py-4 md:py-8">
              初期費用
            </h4>
            <h4 className="text-xl md:text-2xl pb-4 md:pb-6 tracking-wide">
              <span className="text-4xl md:text-5xl font-semibold">
                &#165;36,500
              </span>
              &#160;+税/台
            </h4>
            <p className="text-darkgrey pb-8 md:pb-12 text-xs md:text-base">
              内訳：タブレット端末・初期設定費用
            </p>
            <p>
              <span className="rounded-full bg-grey text-white px-4 py-1 md:py-2">
                オプション
              </span>
            </p>
            <p className="text-base md:text-xl font-semibold pt-6 md:pt-8 pb-2 md:pb-8">
              スタンド
              <span className="text-2xl md:text-3xl mx-2 md:mx-4">
                &#165;3,500
              </span>
              +税/台
            </p>
          </div>

          <div className="basis-2/12 text-blueishgrey text-8xl text-center">
            +
          </div>
          <div className="w-full md:basis-5/12 bg-whitishgrey text-center px-4 py-6 rounded-2xl shadow-md">
            <h4 className="text-2xl md:text-3xl text-appgreen font-semibold py-4 md:py-8">
              月額費用
            </h4>
            <h4 className="text-xl md:text-2xl pb-4 md:pb-6 tracking-wide">
              <span className="text-4xl md:text-5xl font-semibold">
                &#165;600~
              </span>
              &#160;+税/台
            </h4>
            <p className="text-darkgrey pb-8 md:pb-12 text-xs md:text-base">
              内訳：チャットボットを除く全機能
              <br />
              ※導入台数により単価が変動します
            </p>
            <p>
              <span className="rounded-full bg-grey text-white px-4 py-1 md:py-2">
                オプション
              </span>
            </p>
            <p className="text-base md:text-xl font-semibold pt-6 md:pt-8 pb-2 md:pb-8">
              チャットボット
              <span className="text-2xl md:text-3xl mx-2 md:mx-4">
                &#165;300
              </span>
              +税/台
            </p>
          </div>
        </div>
      </article>
    </>
  );
}
