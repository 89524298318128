import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero";
import About from "../components/about";
import Point from "../components/point";
import Feature from "../components/feature";
import Pricing from "../components/pricing";
import Contact from "../components/contact";
import OtherProducts from "../components/other-products";

import { SEO } from "../components/seo"

export default () => (
  <Layout>
    <Hero />
    <About />
    <Point />
    <Feature />
    <Pricing />
    <Contact />
    <OtherProducts/>
  </Layout>
);

export const Head = () => <SEO />;
