import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default () => {
  return (
    <>
      <article id="other-products" className="py-16 md:py-20 w-4/5 mx-auto">
        <h2 className="text-xl md:text-2xl text-darkappgreen font-bold text-center">
          OTHER PRODUCTS
        </h2>

        <div className="text-textnearblack text-center">
          <h2 className="text-2xl md:text-4xl font-bold py-4 tracking-wide mb-8 md:mb-20">
            <span className="px-2">その他プロダクト</span>
          </h2>
        </div>

        <div className="mx-auto w-4/5 md:w-96 text-center">
          <a
            href="https://lp.ai-check-in.com/"
            target="_blank"
            className="py-6 hover:underline underline-offset-8 hover:decoration-linkblue"
          >
            <StaticImage
              src="../images/aicheckin.png"
              alt="AIチェックイン"
              className="rounded-3xl"
            />
            <h4 className="text-linkblue text-xl md:text-2xl font-semibold py-4 md:py-6">
              AIチェックイン
            </h4>
          </a>
          <p className="text-sm md:text-base">
            多岐に渡る宿泊施設のフロント業務を<br/>
            自動化・一元管理できるチェックインシステム
          </p>
        </div>
      </article>
    </>
  );
  }
